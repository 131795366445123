import { Badge, IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { m } from "framer-motion";
import React, { useEffect, useMemo } from "react";
import { WalletService } from "src/api/tz";
import { varHover } from "src/components/animate";
import Iconify from "src/components/iconify";
import { useExchangeRateContext } from "src/exchange-rate";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import { fCurrency } from "src/utils/format-number";

export const WalletBalanceIcon = () => {
    const { exchangeRate } = useExchangeRateContext();

    const { fetchWalletDetails } = WalletService;

    const router = useRouter();

    const companyId = sessionStorage.getItem("companyId");

    const fetchWalletBalance = async () => {
        const response = await fetchWalletDetails(companyId, 3, 1000);

        return response?.data?.balance;
    };

    const { data, isLoading, isFetching } = useQuery({
        queryKey: ["wallet-balance", companyId],
        queryFn: fetchWalletBalance,
        staleTime: 3000,
        refetchOnMount: true,
    });

    const formattedBalance = useMemo(() => {
        if (!data || isNaN(data)) return null;

        return fCurrency(data * exchangeRate.rate, exchangeRate.currency);
    }, [data]);

    return (
        <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            disabled={isLoading || isFetching || !formattedBalance}
            onClick={() => router.push(paths.dashboard.admin.wallet)}
        >
            <Badge color="primary" badgeContent={formattedBalance}>
                <Iconify
                    icon="lucide:wallet"
                    width={32}
                    height={32}
                    style={{
                        color: "#FAFAFA",
                        borderRadius: "10%",
                        marginRight: 2,
                    }}
                />
            </Badge>
        </IconButton>
    );
};
