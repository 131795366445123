import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useCallback, useMemo } from "react";
import { useAuthContext } from "src/auth/hooks";
import { useRouter } from "src/routes/hooks";
import { ICONS } from "src/constants";
import { baseAdminItems, superAdminItems } from "src/constants";
import { useSnackbar } from "src/components/snackbar";

export function useNavData() {
    const { t } = useLocales();
    const router = useRouter();
    const { user, logout } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();

    const role = user?.role || user?.userRole;

    const handleLogout = useCallback(async () => {
        try {
            // Limpio local y session storage después del logout para poder cerrar correctamente la sesión
            await logout();
            localStorage.clear();
            sessionStorage.clear();
            router.replace("/");
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Unable to logout!", { variant: "error" });
        }
    }, [logout, router]);
    const showOptions = useMemo(
        () =>
            role === "superadmin" ||
            (user?.company?.isParent && role === "admin"),
        [role, user],
    );

    const itemsValidated = useMemo(() => {
        if (role === "admin" && showOptions) return baseAdminItems;

        if (role === "superadmin" && showOptions)
            return [baseAdminItems[1], ...superAdminItems];
    }, [role, showOptions]);

    const data = useMemo(
        () => [
            {
                items: [
                    {
                        title: t("Home"),
                        path: paths.dashboard.home.root,
                        icon: ICONS.label,
                    },
                ],
            },

            // Main Menu
            // ----------------------------------------------------------------------
            {
                items: [
                    // // MAIL

                    // PROJECT
                    {
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Itineraries")}
                            </strong>
                        ),
                        icon: ICONS.itinerary,
                        children: [
                            {
                                title: t("Manage Itineraries"),
                                path: paths.dashboard.project.list,
                            },
                            {
                                title: t("Create Itinerary"),
                                path: paths.dashboard.project.new,
                            },
                            {
                                title: t("Create Thematic Itinerary"),
                                path: paths.dashboard.project.thematic,
                            },
                            {
                                title: t("Manage my Own Products"),
                                path: paths.dashboard.ourproducts.root,
                            },
                        ],
                    },

                    {
                        title: t("Bookings"),
                        path: paths.dashboard.admin.salesreport,
                        icon: ICONS.booking,
                        children: [
                            {
                                title: t("All Bookings"),
                                path: paths.dashboard.booking.root,
                                bold: true,
                            },
                            {
                                title: t("My Wallet"),
                                path: paths.dashboard.admin.wallet,
                            },
                            {
                                title: t("Book a Flight"),
                                path: paths.dashboard.flight.home,
                            },
                            {
                                title: t("Book a Hotel"),
                                path: paths.dashboard.hotel.list,
                            },
                            {
                                title: t("Book an Activity"),
                                path: paths.dashboard.activity.list,
                            },
                            {
                                title: t("Book a Transfer"),
                                path: paths.dashboard.transfer.home,
                            },

                            {
                                title: t("Book a Cruise"),
                                path: paths.dashboard.cruises.home,
                                icon: ICONS.cruises,
                            },
                            // {
                            //     title: t("Book a Car - Soon"),
                            //     path: paths.dashboard.carRental.home,
                            //     icon: ICONS.cruises,
                            // },
                        ],
                    },

                    {
                        title: t("Client Management"),
                        path: paths.dashboard.customer.leadList,
                        icon: ICONS.lead,
                        children: [
                            {
                                title: t("Emails"),
                                path: paths.dashboard.mail.root,
                            },
                            {
                                title: t("Leads"),
                                path: paths.dashboard.customer.leadList,
                            },
                            {
                                title: t("Customers"),
                                path: paths.dashboard.customer.individualList, // New overview page
                                children: [
                                    {
                                        title: t("Individuals"),
                                        path: paths.dashboard.customer
                                            .individualList,
                                    },
                                    {
                                        title: t("Businesses"),
                                        path: paths.dashboard.customer
                                            .businessList,
                                    },
                                ],
                            },

                            {
                                title: t("Contact Lists"),
                                path: paths.dashboard.groupleads.list,
                            },
                            {
                                title: t("Customer Acquisition"),
                                path: paths.dashboard.sales.salesPipeline,
                                children: [
                                    {
                                        title: t("Contact Form"),
                                        path: paths.dashboard.contactForm.list,
                                    },
                                    {
                                        title: t("Sales Pipeline"),
                                        path: paths.dashboard.sales
                                            .salesPipeline,
                                    },

                                    {
                                        title: t("Campaigns"),
                                        path: paths.dashboard.campaigns.list,
                                        //icon: ICONS.campaign,
                                    },

                                    {
                                        title: t("Leads Converting"),
                                        path: paths.dashboard.sales
                                            .leadsConverting,
                                    },
                                ],
                            },
                            {
                                title: t("Providers"),
                                path: paths.dashboard.providers.list,
                            },
                        ],
                    },

                    {
                        title: t("Expenses & Invoices"),
                        path: paths.dashboard.admin.salesreport,
                        icon: ICONS.tasks,
                        children: [
                            {
                                title: t("Invoices"),
                                path: paths.dashboard.invoice.root,
                                children: [
                                    {
                                        title: t("see all"),
                                        path: paths.dashboard.invoice.root,
                                    },
                                    {
                                        title: t("create"),
                                        path: paths.dashboard.invoice.new,
                                    },
                                ],
                            },
                            {
                                title: t("Quotations"),
                                path: paths.dashboard.quotation.root,
                                children: [
                                    {
                                        title: t("see all"),
                                        path: paths.dashboard.quotation.root,
                                    },
                                    {
                                        title: t("create"),
                                        path: paths.dashboard.quotation.new,
                                    },
                                ],
                            },
                            {
                                title: t("Income"),
                                path: paths.dashboard.income.list,
                            },
                            {
                                title: t("Expenses"),
                                path: paths.dashboard.expense.list,
                            },
                            {
                                title: t("Accounts"),
                                path: paths.dashboard.chAccount.list,
                            },
                            {
                                title: t("Ledger"),
                                path: paths.dashboard.ledger.list,
                            },
                        ],
                    },
                    {
                        title: t("Reports & Analytics"),
                        path: paths.dashboard.salesOverview,
                        icon: ICONS.dashboard,
                        children: [
                            {
                                title: t("Sales Overview"),
                                path: paths.dashboard.salesOverview,
                            },
                            {
                                title: t("Financials"),
                                path: paths.dashboard.financials,
                            },
                        ],
                    },
                    {
                        title: t("Help Center"),
                        path: paths.dashboard.user.account,
                        icon: ICONS.help,
                        children: [
                            {
                                title: t("Onboarding"),
                                path: paths.dashboard.user.onboarding,
                            },
                            {
                                title: t("Tutorials"), //aqui se pueden ver los pasos que enseñamos al first time user
                                path: paths.dashboard.user.tutorials,
                            },
                            {
                                title: t("Inmediate Support"),
                                path: paths.dashboard.user.support,
                            },
                            {
                                title: t("Support Form"),
                                path: paths.dashboard.kanban,
                            },
                            {
                                title: t("FAQs"),
                                path: paths.dashboard.user.faqs,
                            },
                        ],
                    },
                    {
                        title: t("Settings"),
                        path: paths.dashboard.aipilot.acquisition,
                        icon: ICONS.projects,
                        children: [
                            {
                                title: t("Your Agent Profile"),
                                path: paths.dashboard.user.management,
                            },
                            {
                                title: t("Agency Preferences"),
                                path: paths.dashboard.agency.preferences,
                            },
                            {
                                title: t("Membership"),
                                path: paths.dashboard.agency.membership,
                            },
                            {
                                title: t("Logout"),
                                // path: "",
                                onClick: handleLogout,
                                textColor: "error",
                            },
                        ],
                    },

                    /*} {
                        title: t("Settings"),
                        path: paths.dashboard.aipilot.acquisition,
                        icon: ICONS.settings,
                        children: [
                            {
                                title: t("acquisition"),
                                path: paths.dashboard.aipilot.acquisition,
                            },
                            {
                                title: t("conversion"),
                                path: paths.dashboard.aipilot.conversion,
                            },
                            {
                                title: t("experience"),
                                path: paths.dashboard.aipilot.experience,
                            },
                            {
                                title: t("engagement"),
                                path: paths.dashboard.aipilot.engagement,
                            },
                        ],
                    },*/
                ],
            },
            {
                items: [],
            },
            showOptions && {
                subheader:
                    role === "superadmin" ? t("admin") : t("travelandz admin"),
                items: itemsValidated,
            },
        ],
        [t],
    );

    return data;
}
