const defaultTermsText_en = `
<p><strong>Cancellation Policy:</strong></p>
<p>In the event of cancellation, the following charges apply:</p>
<ul>
  <li>More than 30 days before the trip: No charge</li>
  <li>15-30 days before the trip: 50% of the total trip cost</li>
  <li>Less than 15 days before the trip: 100% of the total trip cost</li>
</ul>

<p><strong>Payment Terms:</strong></p>
<p>A deposit of 30% is required to secure your reservation. The remaining balance is due 15 days before the trip start date.</p>

<p><strong>Changes:</strong></p>
<p>Changes to the reservation can be made up to 15 days before the trip start date, subject to availability.</p>

<p><strong>Availability:</strong></p>
<p>All reservations are subject to availability and confirmation. We reserve the right to cancel or modify reservations based on availability.</p>
`;

    const defaultTermsText_es = `
<p><strong>Política de Cancelación:</strong></p>
<p>En caso de cancelación, se aplicarán los siguientes cargos:</p>
<ul>
  <li>Más de 30 días antes del viaje: Sin cargo</li>
  <li>15-30 días antes del viaje: 50% del costo total del viaje</li>
  <li>Menos de 15 días antes del viaje: 100% del costo total del viaje</li>
</ul>

<p><strong>Términos de Pago:</strong></p>
<p>Se requiere un depósito del 30% para asegurar su reserva. El saldo restante debe pagarse 15 días antes de la fecha de inicio del viaje.</p>

<p><strong>Cambios:</strong></p>
<p>Los cambios en la reserva pueden realizarse hasta 15 días antes de la fecha de inicio del viaje, sujetos a disponibilidad.</p>

<p><strong>Disponibilidad:</strong></p>
<p>Todas las reservas están sujetas a disponibilidad y confirmación. Nos reservamos el derecho de cancelar o modificar reservas en función de la disponibilidad.</p>
`;

export {
    defaultTermsText_en,
    defaultTermsText_es,
}