import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo } from "react";
// components
import { useSettingsContext } from "src/components/settings";
//
import { allLangs, defaultLang } from "./config-lang";
import { useSearchParams } from "src/routes/hooks";
import axiosInstance from "src/config/axiosInstance";

// ----------------------------------------------------------------------

export default function useLocales() {
    const { i18n, t } = useTranslation();
    const settings = useSettingsContext();
    const searchParams = useSearchParams();

    const currentLang = useMemo(() => {
        return (
            allLangs.find((lang) => lang.value === i18n.language) || defaultLang
        );
    }, [i18n.language]);

    const setClientLang = useCallback(
        (value) => {
            const lang =
                value.length === 2 ? value : parseLangShortValue(value);
            sessionStorage.setItem("language", lang);
            i18n.changeLanguage(lang);
            settings.onChangeDirectionByLang?.(lang);
        },
        [i18n, settings],
    );
    /**
     * @param {string} newLang
     * @returns {Promise<boolean>}
     */
    const onChangeLang = useCallback(
        async (newLang) => {
            const prevLang = i18n.language;

            setClientLang(newLang);
            try {
                const longLang =
                    newLang.length === 2
                        ? parseLangLongValue(newLang)
                        : newLang;
                await axiosInstance.patch("/users/edit/language/" + longLang);

                return true;
            } catch (error) {
                console.error("Error changing language", error);
                setClientLang(prevLang);
                return false;
            }
        },
        [i18n, setClientLang],
    );

    useEffect(() => {
        const urlLang = searchParams.get("lang");
        if (urlLang && urlLang !== i18n.language) {
            setClientLang(urlLang);
        }
    }, [searchParams, i18n.language, setClientLang]);

    return {
        allLangs,
        t,
        currentLang,
        onChangeLang,
        setClientLang,
    };
}

const parseLangShortValue = (lang) => {
    switch (lang) {
        case "French":
            return "fr";
        case "Spanish":
            return "es";
        case "English":
        default:
            return "en";
    }
};
const parseLangLongValue = (lang) => {
    switch (lang) {
        case "fr":
            return "French";
        case "es":
            return "Spanish";
        case "en":
        default:
            return "English";
    }
};
