import { useState, useEffect } from "react";
import { parseTotalDuration } from "src/utils";

export const useFlightFilters = (isRoundTrip) => {
    const [flightsData, setFlightsData] = useState({
        offers: [],
        loading: true,
    });

    const [airlines, setAirlines] = useState([]);

    const [airlinesCodes, setAirlinesCodes] = useState([]);

    const [originalData, SetOriginalData] = useState([]);

    const [showSnackBarAfterFilter, setShowSnackBarAfterFilter] =
        useState(false);

    const [paginationData, setPaginationData] = useState({
        pages: 1,
        currentPage: 1,
        itemsPerPage: 10,
    });

    const [filters, setFilters] = useState({
        airlines: [],
        segments: 5,
        duration: "default",
        price: "default",
        departureTime: {
            value: [null, null],
            changed: false,
        },
        arrivalTime: {
            value: [null, null],
            changed: false,
        },
        backFlightArrivalTime: {
            value: [null, null],
            changed: false,
        },
        backFlightDepartureTime: {
            value: [null, null],
            changed: false,
        },
        minDuration: 0,
        maxDuration: 1440,
    });

    useEffect(() => {
        if (originalData?.length <= 0) return;
        getUniqueAirlines();
    }, [originalData]);

    useEffect(() => {
        if (filters.duration === "default") return;
        handleSetFilter({ target: { name: "duration", value: "default" } });
    }, [filters.duration]);

    useEffect(() => {
        if (filters.price === "default") return;
        handleSetFilter({ target: { name: "duration", value: "default" } });
    }, [filters.price]);

    const getUniqueAirlines = () => {
        const airlineCod = [];
        const airlinesList = originalData?.flatMap((offer) =>
            offer?.slices?.flatMap((slice) =>
                slice?.segments?.map((segment) => {
                    airlineCod.push({
                        name: segment?.operating_carrier?.name,
                        code: segment?.operating_carrier?.iata_code,
                    });
                    return segment?.operating_carrier?.name;
                }),
            ),
        );

        setAirlinesCodes([...new Set(airlineCod)]);
        setAirlines([...new Set(airlinesList)]);
    };

    const handleSetFilter = ({ target }) => {
        const { name, value } = target;

        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const clearValues = (setValues = true) => {
        setFilters({
            airlines: [],
            segments: 5,
            duration: "default",
            price: "default",
            departureTime: {
                value: [null, null],
                changed: false,
            },
            arrivalTime: {
                value: [null, null],
                changed: false,
            },
            backFlightArrivalTime: {
                value: [null, null],
                changed: false,
            },
            backFlightDepartureTime: {
                value: [null, null],
                changed: false,
            },
            minDuration: 0,
            maxDuration: 1440,
        });

        if (!setValues) return;

        setFlightsData({
            offers: originalData?.slice(0, paginationData.itemsPerPage) || [],
            loading: false,
            offersWithoutSlice: originalData,
        });

        setPaginationData({
            ...paginationData,
            currentPage: 1,
            pages: Math.ceil(originalData.length / paginationData.itemsPerPage),
        });
    };

    const handleFilter = () => {
        setFlightsData({
            offers: [],
            loading: true,
        });

        let offers = [...originalData];

        offers = filterByDates(offers, "departing");

        offers = filterByDates(offers, "returning");

        offers = filterBySegments(offers);

        offers = filterByDuration(offers);

        offers = filterByAirlines(offers);

        offers = sortByPrice(offers);

        offers = sortByDuration(offers);

        offers = sortBySegments(offers);

        setFlightsData({
            offers:
                offers?.length > 20
                    ? offers?.slice(0, paginationData.itemsPerPage)
                    : offers,
            loading: false,
            offersWithoutSlice: offers,
        });

        setPaginationData({
            ...paginationData,
            currentPage: 1,
            pages: Math.ceil(offers.length / paginationData.itemsPerPage),
        });

        setShowSnackBarAfterFilter(offers?.length <= 0);
    };

    const onChangePage = (page, dialogRef) => {
        if (dialogRef) {
            dialogRef.current.scrollTop = 50;
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        setPaginationData({
            ...paginationData,
            currentPage: page,
        });
        const start = (page - 1) * paginationData.itemsPerPage;
        const end = start + paginationData.itemsPerPage;
        setFlightsData({
            ...flightsData,
            offers: flightsData?.offersWithoutSlice?.slice(start, end),
        });
    };

    const filterByDuration = (offers) => {
        return [...offers]?.filter(
            (offer) =>
                offer?.totalDuration >= filters.minDuration &&
                offer?.totalDuration <= filters.maxDuration,
        );
    };

    const filterBySegments = (offers) => {
        const sumValue = isRoundTrip ? 2 : 1;
        const maxSegments = parseInt(filters.segments) + sumValue;
        const filteredResults = [...offers].filter((item) => {
            return item.totalSegments <= maxSegments;
        });

        return filteredResults;
    };

    const filterByDates = (offers, type) => {
        return [...offers]?.filter((offer) => {
            const value =
                offer.gdsprovider === "duffel" ? "slices" : "itineraries";
            const slicesPos =
                type === "departing" ? 0 : offer?.[value]?.length - 1;
            const [departureFilter, arrivalFilter] =
                type === "departing"
                    ? ["departureTime", "arrivalTime"]
                    : ["backFlightDepartureTime", "backFlightArrivalTime"];
            const sliceSelected = offer?.[value][slicesPos];

            if (!sliceSelected) return false;

            const departingDate =
                offer.gdsprovider === "duffel"
                    ? sliceSelected?.segments[0]?.departing_at
                    : sliceSelected?.segments[0]?.departure?.at;
            const lastSegment =
                sliceSelected?.segments[sliceSelected?.segments?.length - 1];
            const arrivalDate =
                offer.gdsprovider === "duffel"
                    ? lastSegment?.arriving_at
                    : lastSegment?.arrival?.at;

            if (!departingDate || !arrivalDate) return false;

            const [departingHours, departingMinutes] = departingDate
                .split("T")[1]
                .split(":")
                .map(Number);
            const [arrivalHours, arrivalMinutes] = arrivalDate
                .split("T")[1]
                .split(":")
                .map(Number);

            if (filters[departureFilter].changed) {
                const [minDepartureFilter, maxDepartureFilter] =
                    filters[departureFilter].value;

                if (minDepartureFilter) {
                    const minDepartureMinutes = minDepartureFilter.getMinutes();
                    const minDepartureHours = minDepartureFilter.getHours();

                    if (
                        departingHours < minDepartureHours ||
                        (departingHours === minDepartureHours &&
                            departingMinutes < minDepartureMinutes)
                    ) {
                        return false;
                    }
                }

                if (maxDepartureFilter) {
                    const maxDepartureMinutes = maxDepartureFilter.getMinutes();
                    const maxDepartureHours = maxDepartureFilter.getHours();

                    if (
                        departingHours > maxDepartureHours ||
                        (departingHours === maxDepartureHours &&
                            departingMinutes > maxDepartureMinutes)
                    ) {
                        return false;
                    }
                }
            }

            if (filters[arrivalFilter].changed) {
                const [minArrivalFilter, maxArrivalFilter] =
                    filters[arrivalFilter].value;

                if (minArrivalFilter) {
                    const minArrivingMinutes = minArrivalFilter.getMinutes();
                    const minArrivingHours = minArrivalFilter.getHours();

                    if (
                        arrivalHours < minArrivingHours ||
                        (arrivalHours === minArrivingHours &&
                            arrivalMinutes < minArrivingMinutes)
                    ) {
                        return false;
                    }
                }

                if (maxArrivalFilter) {
                    const maxArrivingMinutes = maxArrivalFilter.getMinutes();
                    const maxArrivingHours = maxArrivalFilter.getHours();

                    if (
                        arrivalHours > maxArrivingHours ||
                        (arrivalHours === maxArrivingHours &&
                            arrivalMinutes > maxArrivingMinutes)
                    ) {
                        return false;
                    }
                }
            }

            return true;
        });
    };

    const sortByPrice = (offers) => {
        if (filters.price === "default") return offers;

        const sortedOffers = [...offers]?.sort((a, b) => {
            const amountA = parseFloat(
                a?.gdsprovider === "duffel" ? a?.total_amount : a?.price?.total,
            );
            const amountB = parseFloat(
                b?.gdsprovider === "duffel" ? b?.total_amount : b?.price?.total,
            );

            return filters?.price === "high"
                ? amountB - amountA
                : amountA - amountB;
        });

        return sortedOffers;
    };

    const sortByDuration = (offers) => {
        if (filters.duration === "default") return offers;

        return [...offers]?.sort((a, b) =>
            filters?.duration === "high"
                ? b?.totalDuration - a?.totalDuration
                : a?.totalDuration - b?.totalDuration,
        );
    };

    const sortBySegments = (offers) => {
        if (filters.duration !== "default" || filters.price !== "default")
            return offers;

        return [...offers]?.sort((a, b) => a?.totalSegments - b?.totalSegments);
    };

    const filterByAirlines = (offers) => {
        if (filters.airlines.length === 0) return offers;

        return [...offers]?.filter((offer) =>
            offer?.airlines?.some((airline) => {
                const airlineCode = airlinesCodes?.find(
                    (air) => air?.code === airline,
                );
                return (
                    filters.airlines.includes(airline) ||
                    (airlineCode &&
                        filters.airlines.includes(airlineCode?.name))
                );
            }),
        );
    };

    const getOffersWithTotalValues = (offers) => {
        return offers?.map((offer) => {
            const durations = [];
            const airlines = [];
            let totalSegments = 0;

            const value =
                offer.gdsprovider === "duffel" ? "slices" : "itineraries";

            offer?.[value]?.forEach((slice) => {
                slice?.segments?.forEach((segment) => {
                    if (segment?.duration) {
                        durations.push(segment.duration);
                    }
                    const code =
                        offer?.gdsprovider === "duffel"
                            ? segment?.operating_carrier?.name
                            : segment?.operating?.carrierCode ||
                              segment?.carrierCode;
                    airlines.push(code);
                });
                totalSegments += slice?.segments?.length || 0;
            });

            const totalDurationStr = parseTotalDuration(durations);
            const [hoursStr, , minutesStr] = totalDurationStr.split(" ");
            const totalDurationInMinutes =
                parseInt(hoursStr) * 60 + parseInt(minutesStr);

            return {
                ...offer,
                totalDuration: totalDurationInMinutes,
                totalSegments,
                airlines: [...new Set(airlines)],
            };
        });
    };

    return {
        flightsData,
        originalData,
        setFlightsData,
        SetOriginalData,
        airlines,
        filters,
        handleSetFilter,
        getOffersWithTotalValues,
        clearValues,
        handleFilter,
        ...paginationData,
        onChangePage,
        setPaginationData,
        showSnackBarAfterFilter,
    };
};
