import { createContext, useContext } from "react";

// ----------------------------------------------------------------------

export const GlobalDialogContext = createContext({});

/**
 * @typedef {Object} GlobalDialogContextProps
 * @property {(data: DialogData) => void} triggerDialog
 * @property {() => void} closeDialog
 *
 * @typedef {Object} DialogData
 * @property {string} title
 * @property {Object} content
 * @property {string} content.text
 * @property {"text" | "html"} [content.type]
 * @property {DialogActionLink | DialogActionButton} action
 *
 * @typedef {Object} DialogActionLink
 * @property {"redirect"} type
 * @property {string} href
 * @property {string} text
 * @typedef {Object} DialogActionButton
 * @property {"onclick"} [type]
 * @property {Function} onClick
 * @property {string} text
 */
/**
 *
 * @returns {GlobalDialogContextProps}
 */
export const useGlobalDialogContext = () => {
    const context = useContext(GlobalDialogContext);
    if (!context)
        throw new Error(
            "useGlobalDialogContext must be use inside GlobalDialogContextProvider",
        );

    return context;
};
