import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import axiosInstance from "src/config/axiosInstance";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { shipsByCruiseLine } from "src/sections/cruises/constants";
import { useSnackbar } from "notistack";

const formSchema = Yup.object().shape({
    sea: Yup.string().required("Sea is required"),
    package: Yup.string().required("Package is required"),
    area: Yup.array().min(1, "Area is required"),
    cruiseline: Yup.array().min(1, "Cruise line is required"),
    ship: Yup.array(),
    duration: Yup.string().required("Duration is required"),
    departure: Yup.date().required("Departure is required"),
    arrival: Yup.date().required("Arrival is required"),
});

export const useCruisesSearchForm = () => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            sea: "1",
            package: "all",
            area: [],
            cruiseline: [],
            ship: [],
            duration: [],
        },
        resolver: yupResolver(formSchema),
        mode: "onChange",
    });
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [cruises, setCruises] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [passengers, setPassengers] = useState([
        {
            adults: 0,
            childrenAge: [],
        },
    ]);
    const [availableShips, setAvailableShips] = useState([]);
    const [searchParams, setSearchParams] = useState({
        areas: [],
        cruiselines: [],
        ships: [],
        durations: [],
    });
    const [searchQuery, setSearchQuery] = useState(null);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(1);
    const [departurePorts, setDeparturePorts] = useState({
        selectedPorts: [],
    });
    const [filterdCruises, setFilteredCruises] = useState([]);
    const token = process.env.REACT_APP_TZ_API_KEY;

    useEffect(() => {
        const getSearchParams = async () => {
            try {
                const response = await axiosInstance(
                    `${process.env.REACT_APP_HOST_API}/cruises/searchParams`,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        },
                    },
                );
                if (response.data.ok) {
                    setPages(response.data.result.count.pages);
                    const areas = response.data.result.areas.reduce(
                        (acc, area) => {
                            acc.push({ id: area.id, text: area.text });
                            if (area.children) {
                                area.children.forEach((child) => {
                                    acc.push({
                                        id: child.id,
                                        text: child.text,
                                    });
                                });
                            }
                            return acc;
                        },
                        [],
                    );

                    const cruiselines = Object.entries(
                        response.data.result.cruiselines,
                    ).flatMap(([id, value]) => {
                        return value.data.reduce((acc, line) => {
                            acc.push({ id: line.id, text: line.text });
                            return acc;
                        }, []);
                    });
                    
                    setSearchParams({
                        areas,
                        cruiselines,
                        ships: response.data.result.ships,
                        durations: response.data.result.durations,
                    });
                }
            } catch (error) {
                console.error("Error fetching search params", error);
                enqueueSnackbar("Service unavailable", { variant: "error" });
            }
        };
        getSearchParams();
    }, []);

    const handleCruiseLineChange = (event) => {
        const selectedLine = event.target.value;

        const ships = selectedLine.flatMap((line) => {
            return searchParams.ships[line].data;
        });
        setAvailableShips(ships);
        setValue("cruiseline", selectedLine);
        setValue("ship", []);
    };

    const onFormSubmit = async (value) => {
        setIsLoading(true);
        const projectData = { 
            area: value.area.map((area) => area.text),
            departure: format(value.departure, "yyyy-MM-dd"),
            arrival: value.arrival
                ? format(value.arrival, "yyyy-MM-dd")
                : null,
            currency: sessionStorage.getItem("currency") || "USD",
            companyId: sessionStorage.getItem("companyId"), 
            userId: sessionStorage.getItem("userId"),
        }
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/cruises`,
                {
                    area: value.area.map((area) => area.id),
                    cruiseline: value.cruiseline,
                    ship: value.ship,
                    duration: value.duration,
                    departure: format(value.departure, "yyyy-MM"),
                    arrival: value.arrival
                        ? format(value.arrival, "yyyy-MM")
                        : null,
                    sea: value.sea,
                    package: value.package,
                    page: 1,
                    projectData,
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                },
            );
            if (response.data.result.cruises.length === 0) {
                setNotFound(true);
            } else {
                setNotFound(false);
            }
            setCruises(response.data.result.cruises);
            setFilteredCruises(response.data.result.cruises);
            setDeparturePorts(response.data.result.filter.departurePorts);
            setSearchQuery(value);
            localStorage.setItem("bookingCruisesSession", response.data.sessionId);
        } catch (error) {
            console.error("Error fetching cruises", error);
        } finally {
            setIsLoading(false);
        }
    };

    const onLoadMore = async (page) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/cruises`,
                {
                    area: searchQuery.area.map((area) => area.id),
                    cruiseline: searchQuery.cruiseline,
                    ship: searchQuery.ship,
                    duration: searchQuery.duration,
                    departure: format(searchQuery.departure, "yyyy-MM"),
                    arrival: searchQuery.arrival
                        ? format(searchQuery.arrival, "yyyy-MM")
                        : null,
                    sea: searchQuery.sea,
                    package: searchQuery.package,
                    page,
                },
                { headers: { Authorization: `Bearer ${token}`} },
            );
            if (response.data.result.cruises.length === 0) {
                setNotFound(true);
            } else {
                setNotFound(false);
            }
            setCruises([...cruises, ...response.data.result.cruises]);
            setFilteredCruises([
                ...filterdCruises,
                ...response.data.result.cruises,
            ]);
            setDeparturePorts(response.data.result.filter.departurePorts);
            setPage(page);
        } catch (error) {
            console.error("Error fetching cruises", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClear = () => {
        reset();
        setAvailableShips([]);
    };

    return {
        errors,
        cruises,
        control,
        isLoading,
        passengers,
        searchParams,
        notFound,
        availableShips,
        searchQuery,
        pages,
        page,
        departurePorts,
        filterdCruises,

        setCruises,
        handleClear,
        onFormSubmit,
        setPassengers,
        handleSubmit,
        setValue,
        onLoadMore,
        setSearchParams,
        handleCruiseLineChange,
        setFilteredCruises,
        setDeparturePorts,
    };
};
