import { createSlice } from "@reduxjs/toolkit";

export const itinerarySlice = createSlice({
    name: "itinerary",
    initialState: {
        itineraryData: [],
        loader: {
            type: "",
            loading: false,
        },
        projectData: {},
        grandTotal: 0,
        citiesByDay: [],
        projectTerms: "",
        mapCities: [],
        mapItineraries: [],
        clientViewConfiguration: {},
        isAfterCurrentDate: false,
        draggingItem: {
            value: false,
            type: "",
            slot: "",
        },
    },
    reducers: {
        setItineraryData: (state, action) => {
            state.itineraryData = action.payload.itineraryData;
            state.projectData = action.payload.projectData;
            state.grandTotal = action.payload.grandTotal;
            state.projectTerms = action.payload.projectTerms;
            state.citiesByDay = action.payload.citiesByDay;
            state.mapCities = action.payload?.mapCities;
            state.mapItineraries = action.payload?.mapItineraries;
            state.clientViewConfiguration =
                action.payload?.clientViewConfiguration;
            state.isAfterCurrentDate = action.payload?.isAfterCurrentDate;
        },

        setLoader: (state, action) => {
            state.loader = action.payload;
        },

        addItineraryProduct: (state, action) => {
            const { product, itineraryId } = action.payload;
            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.products.push(product);
                }
                return itinerary;
            });

            let objectToMap = {
                id: product?.productCode,
                name: product?.pageTitle ?? product?.title,
                latitude: product?.attractionLatitude ?? product?.latitude,
                longitude: product?.attractionLongitude ?? product?.longitude,
            };

            state.mapItineraries = state.mapItineraries.map((mapItinerary) => {
                if (mapItinerary.id === itineraryId) {
                    mapItinerary.activities.push(objectToMap);
                }
                return mapItinerary;
            });
        },

        removeItineraryProduct: (state, action) => {
            const { product, itineraryId } = action.payload;
            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.products = itinerary.products.filter(
                        (p) => p.productCode !== product.productCode,
                    );
                }
                return itinerary;
            });

            state.mapItineraries = state.mapItineraries.map((mapItinerary) => {
                if (mapItinerary.id === itineraryId) {
                    mapItinerary.activities = mapItinerary.activities.filter(
                        (activity) => activity.id !== product.productCode,
                    );
                }
                return mapItinerary;
            });
        },

        deleteItineraryFlight: (state, action) => {
            const { flightData, isFlightsPackage } = action.payload;
            state.itineraryData[0] = {
                ...state.itineraryData[0],
                separatedFlights: !isFlightsPackage
                    ? state.itineraryData[0].separatedFlights.filter(
                          (flight) =>
                              !flight.slices.some(
                                  (slice) =>
                                      slice?.duration ===
                                          flightData?.duration &&
                                      slice?.origin.iata_code ===
                                          flightData?.origin?.iata_code &&
                                      slice?.destination.iata_code ===
                                          flightData?.destination?.iata_code,
                              ),
                      )
                    : [],
                flights: isFlightsPackage
                    ? null
                    : state.itineraryData[0].flights,
            };
        },

        deleteItineraryTransfer: (state, action) => {
            const { transferRateKey, itineraryId } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.transports = itinerary.transports.filter(
                        (transfer) =>
                            transfer.transferRateKey !== transferRateKey,
                    );
                }
                return itinerary;
            });
        },

        updateItinerariesTransfers: (state, action) => {
            const { itineraries } = action.payload;
            state.itineraryData = state.itineraryData.map((itinerary) => {
                const updatedItinerary = itineraries.find(
                    (it) => it._id === itinerary._id,
                );
                if (updatedItinerary) {
                    itinerary.transports = updatedItinerary.transports;
                }
                return itinerary;
            });
        },

        updateNewSelectedFlight: (state, action) => {
            const { isFlightsPackage, flightData, newFlight, flightsMap } =
                action.payload;

            if (isFlightsPackage) {
                state.itineraryData[0].flights = newFlight;
            } else {
                state.itineraryData[0].separatedFlights =
                    state.itineraryData[0].separatedFlights.map((flight) => {
                        if (
                            flight.slices.some(
                                (slice) => slice?.id === flightData?.id,
                            )
                        ) {
                            return newFlight;
                        }

                        return flight;
                    });
            }

            state.mapItineraries = state.mapItineraries.map((itineraryMap) => {
                const flightMap = flightsMap?.find(
                    (map) => map.id === itineraryMap.id,
                );

                return {
                    ...itineraryMap,
                    flights: flightMap?.flights || itineraryMap.flights,
                };
            });
        },

        addNewHotel: (state, action) => {
            const { itineraryId, newHotel } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.hotels.push(newHotel);
                }

                return itinerary;
            });
        },

        addManualFlightToStore: (state, action) => {
            const { newFlight } = action.payload;

            const itineraryFlights = state.itineraryData[0];

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary?._id !== itineraryFlights?._id) return itinerary;

                const newSlices = { slices: [newFlight] };

                if (itineraryFlights?.flights) {
                    return {
                        ...itinerary,
                        flights: {
                            ...itinerary.flights,
                            slices: [...itinerary.flights.slices, newFlight],
                        },
                    };
                }

                return {
                    ...itinerary,
                    separatedFlights: [
                        ...(itinerary.separatedFlights || []),
                        newSlices,
                    ],
                };
            });
        },

        updateItineraryProducts: (state, action) => {
            const { itineraryId, products } = action.payload;
            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.products = products;
                }
                return itinerary;
            });
        },

        setSelectedHotel: (state, action) => {
            const { itineraryId, hotelToAdd, add } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    if (add) {
                        itinerary.hotels.push(hotelToAdd);
                        state.mapItineraries = state.mapItineraries.map(
                            (itineraryMap) => {
                                if (itineraryMap.id === itineraryId) {
                                    itineraryMap.hotels.push({
                                        id: hotelToAdd?.code,
                                        name: hotelToAdd?.name,
                                        latitude: hotelToAdd?.latitude,
                                        longitude: hotelToAdd.longitude,
                                        category: hotelToAdd?.category,
                                        pricesFrom: hotelToAdd?.pricesFrom,
                                        tripAdvisorRating:
                                            hotelToAdd?.tripAdvisorRating,
                                    });
                                }
                                return itineraryMap;
                            },
                        );
                    }
                    itinerary.hotelSelected = hotelToAdd;
                }
                return itinerary;
            });
        },

        removeHotel: (state, action) => {
            const { itineraryId, hotelIndex } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    const hotelDeleted = itinerary.hotels.splice(hotelIndex, 1);
                    if (
                        String(hotelDeleted[0].code) ===
                        String(itinerary.hotelSelected.code)
                    ) {
                        itinerary.hotelSelected = {};
                    }
                }

                return itinerary;
            });
        },

        updateOptionalHotels: (state, action) => {
            const { itineraryId, newHotel, hotel } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    if (newHotel) {
                        return {
                            ...itinerary,
                            optionalHotels: [
                                ...itinerary.optionalHotels,
                                newHotel,
                            ],
                        };
                    } else {
                        return {
                            ...itinerary,
                            optionalHotels: itinerary?.optionalHotels?.filter(
                                (optionalHotel) =>
                                    String(optionalHotel.code) !==
                                    String(hotel.code),
                            ),
                        };
                    }
                }

                return itinerary;
            });
        },

        removeOptionalHotel: (state, action) => {
            const { itineraryId, hotel } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    return {
                        ...itinerary,
                        optionalHotels: itinerary?.optionalHotels?.filter(
                            (optionalHotel) =>
                                String(optionalHotel.code) !==
                                String(hotel.code),
                        ),
                    };
                }
                return itinerary;
            });
        },

        updateHotelRate: (state, action) => {
            const { itineraryId, hotel } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    return {
                        ...itinerary,
                        hotels: itinerary.hotels.map((hotelItem) => {
                            if (String(hotelItem.code) === String(hotel.code)) {
                                return hotel;
                            }
                            return hotelItem;
                        }),
                        hotelSelected:
                            String(itinerary?.hotelSelected?.code) ===
                            String(hotel.code)
                                ? hotel
                                : itinerary.hotelSelected,
                    };
                }
                return itinerary;
            });
        },

        updateSelectedActivityTicket: (state, action) => {
            const { itineraryId, productCode, selectedTicket } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.products = itinerary.products.map((product) => {
                        if (product.productCode === productCode) {
                            return {
                                ...product,
                                selectedTicket,
                            };
                        }
                        return product;
                    });
                }
                return itinerary;
            });
        },

        updateClientViewConfiguration: (state, action) => {
            state.clientViewConfiguration =
                action.payload.clientViewConfiguration;
        },

        updateManualTransfers: (state, action) => {
            const { itineraryId, transfer, type } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    if (type === "add") {
                        itinerary.transports = itinerary.transports?.filter(
                            (transferItem) =>
                                transferItem?.mode !== transfer?.mode,
                        );
                        itinerary.transports.push(transfer);
                    } else {
                        itinerary.transports = itinerary.transports.map(
                            (transferItem) => {
                                if (
                                    transferItem.transferRateKey ===
                                    transfer.transferRateKey
                                ) {
                                    return transfer;
                                }

                                return transferItem;
                            },
                        );
                    }
                }
                return itinerary;
            });
        },

        setItineraryProducts: (state, action) => {
            const { itineraryId, draggableId, destination, index } =
                action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    const draggedProduct = itinerary.products.find(
                        (item) => item.productCode?.toString() === draggableId,
                    );

                    if (!draggedProduct) {
                        console.error("Dragged product not found");
                        return itinerary;
                    }

                    const updatedDraggedProduct = {
                        ...draggedProduct,
                        slot: destination?.droppableId,
                    };

                    const filteredItems = itinerary.products.filter(
                        (item) => item.productCode?.toString() !== draggableId,
                    );

                    const updatedItems = [...filteredItems];
                    updatedItems.splice(index, 0, updatedDraggedProduct);

                    return {
                        ...itinerary,
                        products: updatedItems,
                    };
                }
                return itinerary;
            });
        },

        toogleDragging: (state) => {
            state.draggingItem.value = !state.draggingItem.value;
        },
    },
});

export const {
    setItineraryData,
    setLoader,
    addItineraryProduct,
    removeItineraryProduct,
    deleteItineraryFlight,
    deleteItineraryTransfer,
    updateItinerariesTransfers,
    updateNewSelectedFlight,
    addNewHotel,
    addManualFlightToStore,
    updateItineraryProducts,
    setSelectedHotel,
    removeHotel,
    updateOptionalHotels,
    removeOptionalHotel,
    updateHotelRate,
    updateSelectedActivityTicket,
    updateClientViewConfiguration,
    updateManualTransfers,
    toogleDragging,
    setItineraryProducts,
} = itinerarySlice.actions;

export default itinerarySlice.reducer;
