import React, { useState } from "react";
import {
    MAX_HOTEL_ROOMS,
    MAX_HOTEL_ADULTS,
    MAX_HOTEL_KIDS,
    DEFAULT_ROOM_SEARCH,
} from "src/constants";

export function useHotelRooms(adults, kids, infants) {
    const setAges = (kids, infants) => {
        const childrenAges = [];
        for (let i = 0; i < kids; i++) {
            childrenAges.push(8);
        }
        for (let i = 0; i < infants; i++) {
            childrenAges.push(2);
        }
        return childrenAges;
    };

    const [rooms, setRooms] = useState([
        {
            adults: adults,
            kids: kids + infants,
            childrenAges: setAges(kids, infants),
        },
    ]);

    const handleAddRoom = () => {
        if (rooms.length >= MAX_HOTEL_ROOMS) return;
        setRooms((prev) => [
            ...prev,
            { adults: 2, kids: 0, childrenAges: setAges(kids, infants) },
        ]);
    };
    const handleRemoveRoom = () => {
        if (rooms.length <= 1) return;
        setRooms((prev) => {
            const newArray = prev.slice(0, -1);
            return newArray;
        });
    };
    const handleRemoveAdult = (roomIndex) => {
        setRooms((prev) => {
            return prev.map((room, index) => {
                if (roomIndex === index && room.adults > 1) {
                    room.adults -= 1;
                }
                return room;
            });
        });
    };
    const handleAddAdult = (roomIndex) => {
        setRooms((prev) => {
            return prev.map((room, index) => {
                if (roomIndex === index && room.adults < MAX_HOTEL_ADULTS) {
                    room.adults += 1;
                }
                return room;
            });
        });
    };
    const handleRemoveKid = (roomIndex) => {
        setRooms((prev) => {
            return prev.map((room, index) => {
                if (roomIndex === index && room.kids > 0) {
                    room.kids -= 1;
                    room.childrenAges.pop();
                }
                return room;
            });
        });
    };
    const handleAddKid = (roomIndex) => {
        setRooms((prev) => {
            return prev.map((room, index) => {
                if (roomIndex === index && room.kids < MAX_HOTEL_KIDS) {
                    room.kids += 1;
                    room.childrenAges.push(0);
                }
                return room;
            });
        });
    };
    const handleChangeAge = (age, roomIndex, childIndex) => {
        const numberAge = Number(age);
        if (isNaN(numberAge) || numberAge > 18 || numberAge < 0) return;
        setRooms((prev) => {
            return prev.map((room, index) => {
                if (roomIndex === index) {
                    room.childrenAges[childIndex] = numberAge;
                }
                return room;
            });
        });
    };

    return {
        rooms,
        onChange: {
            handleAddRoom,
            handleRemoveRoom,
            handleAddAdult,
            handleRemoveAdult,
            handleAddKid,
            handleRemoveKid,
            handleChangeAge,
        },
    };
}
