import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { defaultLang } from "./config-lang";
//
import translationEn from "./langs/en.json";
import translationFr from "./langs/fr.json";
import translationEs from "./langs/es.json";
import translationTh from "./langs/th.json";

// ----------------------------------------------------------------------

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translations: translationEn },
            fr: { translations: translationFr },
            es: { translations: translationEs },
            th: { translations: translationTh },
        },
        fallbackLng: defaultLang.value,
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: [
                "querystring",
                "sessionStorage",
                "localStorage",
                "navigator",
                "htmlTag",
            ],
            lookupQuerystring: "lang",
            lookupLocalStorage: "i18nextLng",
            lookupSessionStorage: "language",
        },
    });

export default i18n;
