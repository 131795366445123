import { useEffect, useState } from "react";

/**
 *
 * @param {Array} posibleFields
 * @param {Array} selectedFields
 * @returns {Object}
 * - options: Array
 * - fields: Object
 * - handleToogleOption: Function
 * - handleOnChangeOption: Function
 *
 */

export const useCSVSetUpFields = (posibleFields, selectedFields) => {
    const [fields, setFields] = useState(selectedFields);

    const [options, setOptions] = useState();

    useEffect(() => {

        if(!posibleFields || !selectedFields) return;

        setOptions(
            posibleFields.map((field) => ({
                ...field,
                isSelected: Object.keys(selectedFields).includes(field.value),
            })),
        );

    }, [selectedFields, posibleFields]);

    useEffect(()=>{
        setFields(selectedFields);
    },[selectedFields])

    const handleToogleOption = (option) => {
        if (
            options.some(
                (SelOption) =>
                    SelOption.value === option.value && SelOption.isSelected,
            )
        ) {
            setOptions((prev) =>
                prev.map((currentOption) => ({
                    ...currentOption,
                    isSelected:
                        currentOption.value === option.value
                            ? false
                            : currentOption.isSelected,
                })),
            );
            const currentFields = fields;
            delete currentFields[option.value];
            setFields({ ...currentFields });
        } else {
            setOptions((prev) =>
                prev.map((currentOption) => ({
                    ...currentOption,
                    isSelected:
                        currentOption.value === option.value
                            ? true
                            : currentOption.isSelected,
                })),
            );
            setFields({ ...fields, [option.value]: "" });
        }
    };

    const handleOnChangeValue = (key, value) => {
        setFields({ ...fields, [key]: value });
    };

    return {
        options,
        fields,
        handleToogleOption,
        handleOnChangeValue,
    };
};
