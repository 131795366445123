import { Suspense } from "react";
import { HomePage, mainRoutes } from "./main";
import { Navigate, useRoutes } from "react-router-dom";

import MainLayout from "src/layouts/main";
import { authDemoRoutes } from "./auth-demo";
import { authRoutes } from "./auth";
import { dashboardRoutes } from "./dashboard";

export default function Router() {
    return useRoutes([
        {
            path: "/",
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <MainLayout>
                        <HomePage />
                    </MainLayout>
                </Suspense>
            ),
        },
        ...authRoutes,
        ...authDemoRoutes,
        ...dashboardRoutes,
        ...mainRoutes,
        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}
