import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axiosInstance from "src/config/axiosInstance";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import { addNewHotel } from "src/store/itinerarySlice";

export const useSearchHotels = (
    itineraryId,
    handleCloseDialog,
) => {
    const [loading, setLoading] = useState({
        search: false,
        save: false,
    });

    const dispatch = useDispatch();

    const [searchResults, setSearchResults] = useState([]);
    const [hotelSelected, setHotelSelected] = useState({});
    const [{ hotelName, hotelCity }, setHotelForm] = useState({
        hotelName: "",
        hotelCity: "",
    });
    const router = useRouter();
    const companyId = sessionStorage.getItem("companyId");

    console.log(itineraryId, "itineraryId final fox");

    useEffect(() => {
        if (hotelSelected?.name) {
            handleSaveHotel(hotelSelected);
        }
    }, [hotelSelected]);

    const handleSearchInfo = async () => {
        if (hotelName.trim() === "" || hotelCity.trim() === "") {
            return;
        }
        setLoading({
            ...loading,
            search: true,
        });
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/hotels/search`,
                {
                    name: hotelName,
                    place: hotelCity,
                },
            );

            setSearchResults(response.data.hotels);
            setLoading({
                ...loading,
                search: false,
            });
        } catch (error) {
            console.error("Error searching for hotels:", error);
            setLoading({
                ...loading,
                search: false,
            });
        }
    };

    const handleSaveHotel = async (hotel) => {
        setLoading({
            ...loading,
            save: true,
        });
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/hotels/new`,
                {
                    name: hotel.name,
                    place: hotel.address_obj?.city,
                    hotelId: hotel.location_id,
                    searchQuery: "tripadvisor",
                    companyId: companyId,
                    itineraryId: itineraryId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            setLoading({
                ...loading,
                save: false,
            });

            if (response.data?.hotels) {
                const hotelCode = response.data?.hotels[0]?.code;
                const gdsProvider =
                    response.data?.hotels[0]?.gds_provider || "hotelbeds";
                router.push(
                    paths.dashboard.hotel.details(gdsProvider, hotelCode),
                );
            } else {
                const newHotel = response.data.hotelToItinerary;
                dispatch(addNewHotel({ newHotel, itineraryId }));
                handleCloseDialog();
            }
        } catch (error) {
            console.error("Error saving hotel:", error);
            if (error.response) {
                console.error("API Error:", error.response.data);
            }
            setLoading({
                ...loading,
                save: false,
            });
        }
    };

    return {
        loading,
        searchResults,
        hotelSelected,
        setHotelForm,
        setHotelSelected,
        handleSearchInfo,
        handleSaveHotel,
    };
};
