import { useEffect, useRef, useState } from "react";
import { MagicBellProvider } from "@magicbell/react-headless";
import axiosInstance from "src/config/axiosInstance";
import { useLocales } from "src/locales";
import { useAuthContext } from "src/auth/hooks";

import NotificationsInbox from "./NotificationsInbox";
import { IconButton } from "@mui/material";
import Iconify from "src/components/iconify";

const stores = [
    { id: "default", defaultQueryParams: {} },
    { id: "unread", defaultQueryParams: { read: false } },
    // { id: "archived", defaultQueryParams: { archived: true } },
];

export function NotificationsMagicBell() {
    const { currentLang } = useLocales();
    const { user } = useAuthContext();
    const [isOpen, setIsOpen] = useState(false);
    const isFetching = useRef(false);
    const [apiKey, setApiKey] = useState(null);

    useEffect(() => {
        if (!user?._id || !user?.notificationAlert?.notifications) {
            setApiKey(null);
            return;
        }
        if (isFetching.current) return;
        const fetchMagicBellApiKey = async () => {
            isFetching.current = true;
            try {
                const { data } = await axiosInstance.get(
                    "/integrations/magic-bell",
                );
                setApiKey(data.apiKey);
            } catch (error) {
                console.error("Error fetching Notifications Provider");
            }
            isFetching.current = false;
        };
        // get API
        fetchMagicBellApiKey();
    }, [user?._id, user?.notificationAlert?.notifications]);

    if (!user?._id || !user?.notificationAlert?.notifications) return null;

    if (!apiKey) {
        return (
            <div className="min-w-[42px] mx-2 opacity-70 cursor-wait">
                <IconButton
                    whileTap="tap"
                    whileHover="hover"
                    color="default"
                    disabled
                >
                    <Iconify
                        icon="solar:bell-outline"
                        width={32}
                        height={32}
                        style={{
                            color: "#FAFAFA",
                            borderRadius: "10%",
                            marginRight: 2,
                        }}
                    />
                </IconButton>
            </div>
        );
    }
    return (
        <div className="min-w-[42px] mx-2">
            <MagicBellProvider
                apiKey={apiKey}
                userEmail={user.email}
                userExternalId={user._id}
                stores={stores}
                locale={currentLang.value}
            >
                <NotificationsInbox
                    isOpen={isOpen}
                    onToggle={() => setIsOpen((prev) => !prev)}
                />
            </MagicBellProvider>
        </div>
    );
}
