import { t } from "i18next";

const IncludedDialogReplaceValues = [
    {
        text: "Hotel in",
        value: t("Hotel in"),
    },
    {
        text: "Flight",
        value: t("Flight"),
    },
    {
        text: "ACTIVITY",
        value: t("Activity"),
    },
    {
        text:"Departing",
        value:t("Departing"),
    },
    {
        text:"Arriving",
        value:t("Arriving"),
    },
    {
        text:"Route",
        value:t("Route"),
    },
    {
        text:"Passengers",
        value:t("Passengers"),
    },
    {
        text:"Category",
        value:t("Category"),
    },
    {
        text:"Zone",
        value:t("Zone"),
    },
    {
        text:"Duration",
        value:t("Duration"),
    },
    {
        text:"Cancellation Policy",
        value:t("Cancellation Policy"),
    },
    {
        text:"Cabin Class",
        value:t("Cabin Class"),
    },
    {
        text:"Passenger",
        value:t("Passenger"),
    },
    {
        text:"Baggage",
        value:t("Baggage"),
    },
    {
        text:"ADULT",
        value:t("Adult"),
    },
    {
        text:"Language",
        value:t("Language"),
    },
    {
        text:"Not included",
        value:t("Not included"),
    },
    {
        text:"Important",
        value:t("Important"),
    },
    {
        text:"Itinerary",
        value:t("Itinerary"),
    },
    {
        text:"Step",
        value:t("Step"),
    },
    {
        text:"Pass By Without Stopping",
        value:t("Pass By Without Stopping"),
    },
    {
        text:"minutes",
        value:t("minutes"),
    },
    {
        text:"hours",
        value:t("hours"),
    },
    {
        text:"Skip the Line",
        value:t("Skip the Line"),
    },
    {
        text:"Private Tour",
        value:t("Private Tour"),
    },
    {
        text:"Transport",
        value:t("Transport"),
    },
    {
        text:"Mode",
        value:t("Mode"),
    },
    {
        text:"departure",
        value:t("departure"),
    },
    {
        text:"arrival",
        value:t("arrival"),
    },
    {
        text:"Description",
        value:t("Description"),
    },
    {
        text:"Car",
        value:t("Car"),
    },
    {
        text:"Booking Status",
        value:t("Booking Status"),
    },
    {
        text:"waitingforquotation",
        value:t("waitingforquotation"),
    }
];


export {
    IncludedDialogReplaceValues,
};