import { useMediaQuery } from "@mui/system";

const BREAKPOINTS = {
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 1024px)",
    xl: "(min-width: 1280px)",
    "2xl": "(min-width: 1536px)",
};
/**
 *
 * @param {"sm"|"md"|"lg"|"xl"|"2xl"} breakpoint
 * @returns
 */
export function useTailwindMediaQuery(breakpoint) {
    return useMediaQuery(BREAKPOINTS[breakpoint] || "");
}
