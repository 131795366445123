import { debounce } from "lodash";
import { useMemo, useState } from "react";
import axiosInstance from "src/config/axiosInstance";

export const useProjectQuickUpdate = () => {
    const [originAirportList, setOriginAirportList] = useState([]);
    const [destinationAirportList, setdestinationAirportList] = useState([]);
    const [origen, setOrigen] = useState("");

    const getAirportsList = async (input) => {
        const response = await axiosInstance.get(
            `${process.env.REACT_APP_HOST_API}/flights/location/${input}`,
        );
        return response.data;
    };

    const onDestinationChange = debounce(async (value, type) => {
        if (value === "") return;
        try {
            setOrigen(value);
            if (value.length < 3) {
                return;
            }
            const result = await getAirportsList(value);
            const filteredResult = result.map((item, index) => ({
                label: `${item.name}, ${item.country_name}`,
                code: item.code,
                id: index,
            }));

            if (type === "origin") {
                setOriginAirportList(filteredResult);
            } else {
                setdestinationAirportList(filteredResult);
            }
        } catch (err) {}
    }, 300);

    return {
        originAirportList: useMemo(() => originAirportList, [originAirportList]),
        onDestinationChange: useMemo(() => onDestinationChange, []),
        origen: useMemo(() => origen, [origen]),
        destinationAirportList: useMemo(() => destinationAirportList, [destinationAirportList]),

        setdestinationAirportList, 
    }
}; 