export const areas = [
    { id: "100", text: "Africa" },
    { id: "51", text: "Round Africa" },
    { id: "5", text: "South Africa" },
    { id: "102", text: "America" },
    { id: "4", text: "Central America/Caribbean" },
    { id: "202", text: "North America" },
    { id: "10", text: "South America" },
    { id: "84", text: "Arctic" },
    { id: "86", text: "Arctic" },
    { id: "87", text: "Greenland" },
    { id: "63", text: "Northwest Passage" },
    { id: "103", text: "Asia" },
    { id: "12", text: "Far East" },
    { id: "15", text: "Indian Ocean" },
    { id: "19", text: "South East Asia" },
    { id: "105", text: "Europe" },
    { id: "68", text: "Mediterranean" },
    { id: "205", text: "Northeurope" },
    { id: "206", text: "Southeurope" },
    { id: "207", text: "Westeurope" },
    { id: "108", text: "Middle East" },
    { id: "23", text: "Arabic Sea" },
    { id: "46", text: "Red Sea" },
    { id: "104", text: "Pacific" },
    { id: "18", text: "Australia" },
    { id: "11", text: "Hawaii" },
    { id: "54", text: "Mexican Riviera" },
    { id: "13", text: "South Pacific" },
    { id: "107", text: "Trans-Cruises" },
    { id: "58", text: "Transafrica" },
    { id: "59", text: "Transamerica" },
    { id: "60", text: "Transasia" },
    { id: "9", text: "Transatlantic" },
    { id: "56", text: "Transorient" },
    { id: "57", text: "Transpacific" },
];
