import { useState, useEffect, useRef } from "react";

const INITIAL_FILTERS = {
    priceLevel: "default",
    landmark: "",
    name: "",
    price: "default",
    amenities: [],
    acommodationType: "default",
    zones: [],
    tripAdvisorStyle: "",
};
const INITIAL_UNIQUE_VALUES = {
    landmarks: [],
    amenities: [],
    accomodationTypes: [],
    zones: [],
    tripAdvisorStyles: [],
};

export const useHotelFilter = () => {
    const [originalData, setOriginalData] = useState([]);

    const [hotelsData, setHotelsData] = useState([]);

    const [uniqueValues, setUniqueValues] = useState(INITIAL_UNIQUE_VALUES);

    const [filters, setFilters] = useState(INITIAL_FILTERS);

    const priceLevelsRef = useRef({
        lowMediumPriceLevel: 0,
        mediumHighPriceLevel: 0,
    });

    useEffect(() => {
        if (originalData?.length <= 0) return;
        getUniqueValues();
    }, [originalData]);

    const handleFilter = () => {
        let offers =
            originalData?.filter((offer) => {
                if (!filterOfferByZone(offer, filters.zones)) return false;
                if (!filterOfferByHotelName(offer, filters.name)) return false;
                if (
                    !filterOfferByPriceLevel(
                        offer,
                        filters.priceLevel,
                        priceLevelsRef.current,
                    )
                )
                    return false;
                if (!filterOfferByLandMark(offer, filters.landmark))
                    return false;
                if (!filterOfferByAmenity(offer, filters.amenities))
                    return false;
                if (
                    !filterOfferByAccomodationType(
                        offer,
                        filters.acommodationType,
                    )
                )
                    return false;
                if (
                    !filterOfferByTripAdvisorStyles(
                        offer,
                        filters.tripAdvisorStyle,
                    )
                )
                    return false;
                return true;
            }) || [];

        offers = sortByPrice(offers);
        setHotelsData(offers);
    };

    const setData = (data) => {
        if (data?.length <= 0) return;

        setOriginalData(data);

        setHotelsData(data);
    };

    const getUniqueValues = () => {
        const prices = originalData?.map((offer) => {
            return +Math.floor(offer?.pricesFrom || 0);
        });
        const priceLevels = Array.from(new Set(prices)).sort((a, b) => a - b);
        const third = Math.floor(priceLevels.length / 3);

        const lowMediumPriceLevel = priceLevels[third];
        const mediumHighPriceLevel = priceLevels[2 * third];
        priceLevelsRef.current = { lowMediumPriceLevel, mediumHighPriceLevel };

        const landMarks =
            originalData?.flatMap((offer) =>
                offer?.interestPoints?.map((point) => point?.poiName),
            ) || [];

        const amenities = originalData
            ?.filter((offer) => offer?.amenities?.length > 0)
            ?.flatMap((offer) => offer?.amenities?.map((amenity) => amenity));

        const accomodationTypes = originalData
            ?.filter((offer) => offer?.accommodationType?.length > 0)
            ?.map((offer) => offer?.accommodationType);

        const zones = originalData?.map((offer) => offer?.city);

        const tripAdvisorStyles = originalData?.flatMap(
            (offer) => offer?.styles || [],
        );
        setUniqueValues({
            landmarks: Array.from(
                new Set(landMarks?.filter((landMark) => landMark?.length > 0)),
            ),
            amenities: Array.from(new Set(amenities)),
            accomodationTypes: Array.from(new Set(accomodationTypes)),
            zones: Array.from(new Set(zones)),
            tripAdvisorStyles: Array.from(new Set(tripAdvisorStyles)),
        });
    };

    const handleChangeFilter = (name, value) => {
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const clearValues = (resetData = false) => {
        setFilters(INITIAL_FILTERS);

        if (resetData) {
            setHotelsData([]);
            setOriginalData([]);
            setUniqueValues(INITIAL_UNIQUE_VALUES);
        } else {
            setHotelsData(originalData);
        }
    };

    const sortByPrice = (offers) => {
        if (filters?.price === "default") return offers;

        return offers?.sort((a, b) => {
            const amountA = parseFloat(a?.pricesFrom);
            const amountB = parseFloat(b?.pricesFrom);

            return filters?.price === "high"
                ? amountB - amountA
                : amountA - amountB;
        });
    };

    return {
        setData,
        hotelsData,
        handleChangeFilter,
        clearValues,
        handleFilter,
        originalData,
        filters,
        uniqueValues,
    };
};

const filterOfferByZone = (offer, zones) => {
    if (zones?.length <= 0) return true;
    return zones?.includes(offer?.city);
};

const filterOfferByHotelName = (offer, name) => {
    if (!name?.trim()) return true;

    const searchTerm = name.trim().toLowerCase().replace(/\s+/g, "");
    return offer?.name
        ?.trim()
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(searchTerm);
};

const filterOfferByPriceLevel = (offer, priceLevel, priceLevels) => {
    if (!priceLevel || priceLevel === "default") return true;
    if (priceLevel === "Low") {
        return offer?.pricesFrom < priceLevels.lowMediumPriceLevel;
    } else if (priceLevel === "High") {
        return offer?.pricesFrom > priceLevels.mediumHighPriceLevel;
    } else {
        return (
            offer?.pricesFrom >= priceLevels.lowMediumPriceLevel &&
            offer?.pricesFrom <= priceLevels.mediumHighPriceLevel
        );
    }
};

const filterOfferByLandMark = (offer, landmark) => {
    if (!landmark) return true;
    const landMark = landmark.trim().toLowerCase();
    return offer?.interestPoints?.some(
        (point) => point?.poiName?.trim().toLowerCase() === landMark,
    );
};

const filterOfferByAmenity = (offer, amenities) => {
    if (amenities?.length <= 0) return true;
    return offer?.amenities?.some?.((amenity) => amenities?.includes(amenity));
};
const filterOfferByAccomodationType = (offer, acommodationType) => {
    if (!acommodationType || acommodationType === "default") return true;
    return offer?.accommodationType === acommodationType;
};

const filterOfferByTripAdvisorStyles = (offer, tripAdvisorStyle) => {
    if (!tripAdvisorStyle) return true;
    return offer?.styles?.includes(tripAdvisorStyle);
};
