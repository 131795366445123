import { useEffect, useState } from "react";

/**
 * @param {Object} params - The parameters for the hook.
 * @param {string[]} params.quotes - An array of existing quotes.
 * @param {Function} params.setMailOptions - Function to update the mail options.
 * @returns {Object} - The state and handlers for custom quotes.
 */
export const useCustomQuotes = ({ quotes, setMailOptions }) => {
    const [customQuote, setCustomQuote] = useState("");
    const [error, setError] = useState(false);
    useEffect(() => {
        const errorTimeout = setTimeout(() => {
            setError(false);
        }, 3000);

        return () => clearTimeout(errorTimeout);
    }, [error]);

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
     */
    const handleCustomQuoteInputChange = (event) => {
        setCustomQuote(event.target.value);
    };

    const addCustomQuote = () => {
        setMailOptions((prev) => ({
            ...prev,
            customQuotes: [...prev.customQuotes, customQuote],
        }));
        setCustomQuote("");
    };

    const removeLastQuote = () => {
        setMailOptions((prev) => ({
            ...prev,
            customQuotes: prev.customQuotes.slice(0, -1),
        }));
    };

    /**
     * @param {React.KeyboardEvent | React.MouseEvent} event - The key or click event.
     */
    const handleCustomQuote = (event) => {
        const { key, type } = event;
        const isEnter = key === "Enter";
        const isBackspace = key === "Backspace";
        const isClick = type === "click";
        const isQuoteExists = quotes.includes(customQuote);

        if (isQuoteExists) {
            setError(true);
            return;
        }

        if (customQuote.trim() === "") {
            if (isBackspace) {
                removeLastQuote();
            }
            return;
        }

        if (isClick || isEnter) {
            addCustomQuote();
        }
    };

    /**
     * @param {string} quote - The quote to delete.
     */
    const handleDelete = (quote) => {
        setMailOptions((prev) => ({
            ...prev,
            customQuotes: prev.customQuotes.filter((q) => q !== quote),
        }));
    };

    return {
        customQuote,
        error,
        handleCustomQuoteInputChange,
        handleCustomQuote,
        handleDelete,
    };
};
