import { useMemo } from "react";
import Geonames from "geonames.js";
import { useSnackbar } from "notistack";
import { useLocales } from "src/locales";

export const useGeonames = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { currentLang } = useLocales();

    const geonames = useMemo(() => {
        return Geonames({
            username: process.env.REACT_APP_GEONAMES_USERNAME,
            lan: currentLang.value || "en",
            encoding: "JSON",
        });
    }, []);

    const searchCities = async (input) => {
        try {
            const cities = await geonames.search({
                q: input,
                maxRows: 20,
                featureClass: "P",
            });
            return cities.geonames.map((city) => ({
                geonameid: city.geonameId,
                name: city.name,
                country: city.countryName,
                province: city.adminName1,
            }));
        } catch (error) {
            enqueueSnackbar("Error searching cities", { variant: "error" });
            return [];
        }
    };

    return { searchCities };
};
