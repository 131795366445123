import { parseCSV } from "../utils";
import { useCallback } from "react";
import { useSnackbar } from "notistack"; // Import notistack for Snackbar notifications

export const useProcessCSV = (
    addFunction,
    fetchFunction,
    transformFunction,
) => {
    const { enqueueSnackbar } = useSnackbar(); // Initialize the Snackbar hook

    const handleFileUpload = useCallback(
        async (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const csvText = e.target.result;

                    try {
                        const parsedData = await parseCSV(csvText);
                        const transformedData = transformFunction(parsedData);

                        const response = await addFunction(transformedData);

                        if (response?.data?.savedCustomers?.length > 0) {
                            enqueueSnackbar(
                                `${response?.data?.savedCustomers?.length} customers imported successfully.`,
                                { variant: "success" },
                            );
                        }

                        // Handle skipped customers
                        if (response?.data?.skippedCustomers?.length > 0) {
                            const skippedCustomerNames =
                                response?.data?.skippedCustomers
                                    .map(
                                        (customer) =>
                                            customer?.name ||
                                            customer?.email ||
                                            customer?.phone,
                                    ) // Adjust the field as per your data
                                    .join(", ");

                            enqueueSnackbar(
                                `The following customers were skipped because they already exist: ${skippedCustomerNames}`,
                                { variant: "warning" },
                            );
                        }

                        // Fetch the updated list if fetchFunction is provided
                        if (fetchFunction) {
                            await fetchFunction();
                        }
                    } catch (error) {
                        console.error("Error processing CSV data:", error);
                        enqueueSnackbar(
                            "An error occurred while processing the CSV file.",
                            {
                                variant: "error",
                            },
                        );
                    }

                    event.target.value = ""; // Clear the input file after processing
                };
                reader.readAsText(file);
            }
        },
        [addFunction, fetchFunction, transformFunction, enqueueSnackbar], // Include enqueueSnackbar in dependencies
    );

    return { handleFileUpload };
};
