import { axiosInstance } from "src/config/axiosInstance";

const SPECIAL_TOKEN = process.env.REACT_APP_TZ_API_KEY;

class ProjectsService {
    static async updateProjectTerms(projectId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/projects/updateTerms/${projectId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async fetchProjectsList(
        companyId,
        maxRetries,
        retryDelay,
        timeout = 7000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/company/${companyId}`,
                    {
                        timeout: timeout,
                    },
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteProjectById(projectId) {
        try {
            const response = await axiosInstance.post(
                `/projects/delete/${projectId}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async deleteProjects(emailsIds) {
        try {
            const response = await axiosInstance.delete(
                `/projects/delete`,
                emailsIds,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async archiveProjects(emailsIds) {
        try {
            const response = await axiosInstance.delete(
                `/projects/delete`,
                emailsIds,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async fetchProjectDetails(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/${projectId}`,
                    {
                        headers: {
                            "tz-api-key": process.env.REACT_APP_SPECIAL_KEY,
                        },
                    },
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async fetchProjectDetailsAndItinerary(
        projectId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/detailsAndItinerary/${projectId}`,
                    {
                        timeout: 7000,
                    },
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
                throw error;
            }
        }
    }

    static async fetchProjectDetailsByLast8Digits(
        last8Digits,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                console.log(
                    "Fetching project details by last 8 digits:",
                    last8Digits,
                );
                const response = await axiosInstance.get(
                    `/projects/notifications/${last8Digits}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createProject(data, maxRetries = 3, retryDelay = 1000) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/projects/new`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        // timeout: 5000, // Set a reasonable timeout (in milliseconds) for the request
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    `Attempt ${retries} - Error creating project:`,
                    error,
                );
                retries++;
                if (retries === maxRetries) throw error;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchWorkUser(userId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/projects/user/${userId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async fetchRecentActivity(userId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/projects/recentActivity/${userId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchProjectsSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/summary/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Después de los intentos, lanzar una excepción indicando el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchProjectsSummaryVat(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/summary/parents/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Después de los intentos, lanzar una excepción indicando el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchDataDashboard(companyId, data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const url = `/projects/dashboard/${companyId}`;

                // Verificar si se proporcionan fechas y agregarlas a la URL si es así
                if (data) {
                    const response = await axiosInstance.post(
                        `/projects/dashboard/${companyId}`,
                        data,
                    );
                    return response;
                } else {
                    return await axiosInstance.get(url);
                }
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Si llegamos a este punto, lanzar una excepción para indicar el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchProjectPreferences(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/preferences/${projectId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async saveFeedback(data, viewType) {
        try {
            const response = await axiosInstance.post(`/feedback/new`, data, {
                headers:
                    viewType === "agent"
                        ? undefined
                        : { "tz-api-key": SPECIAL_TOKEN },
            });
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async saveProviderFeedback(data) {
        try {
            console.log("data", data);
            const response = await axiosInstance.post(
                `/feedback/provider/new`,
                data,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async saveItemFeedback(data) {
        try {
            const response = await axiosInstance.post(
                `/feedback/item/new`,
                data,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async addNextPayment(projectId, data) {
        return axiosInstance.post(
            `/projects/addNextPayment/${projectId}`,
            data,
        );
    }
    static async askForPayment(projectId, data) {
        return axiosInstance.post(`/projects/askForPayment/${projectId}`, data);
    }

    static async convertProjectInATour(projectId, data) {
        try {
            const response = await axiosInstance.post(
                `/projects/createTourFromProject/${projectId}`,
                data,
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while converting the project into a tour:",
                error,
            );
            throw error;
        }
    }

    static async CalculateProjectPrice(projectId, body) {
        try {
            const response = await axiosInstance.put(
                `/projects/calculateProjectPrice/${projectId}`,
                body,
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while calculating the project price:",
                error,
            );
            return { error: true };
        }
    }

    static async checkOffersFlights(projectId) {
        try {
            const response = await axiosInstance.put(
                `/projects/udapteFligthsOffers/${projectId}`,
            );

            return response?.data;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async updateBackgroundImage(projectId, imageUrl) {
        try {
            const response = await axiosInstance.put(
                `/projects/updateBackgroundImage/${projectId}`,
                { imageUrl },
            );

            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async updatePhotoPlaceImage(projectId, imageUrl) {
        try {
            const response = await axiosInstance.put(
                `/projects/changeProjectImage/${projectId}`,
                { imageUrl },
            );

            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async updateProjectHotelProposal(body, projectId) {
        try {
            const response = await axiosInstance.put(
                `/projects/updateProjectHotelProposal/${projectId}`,
                body,
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async handleSaveProjectTerms(id, termsContent, showTerms) {
        try {
            const response = await axiosInstance.put(
                `/projects/updateTerms/${id}`,
                { termsContent, showTerms, id },
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async updateCustomMessage(projectId, customMessage) {
        try {
            const response = await axiosInstance.patch(
                `/projects/updateCustomMessage/${projectId}`,
                {
                    customMessage,
                },
            );

            return response.data;

        } catch (error) {
            throw error;
        }
    }
}

export default ProjectsService;
